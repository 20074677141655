.phishing_wrap {
  margin-bottom: 120px;
  text-align: center;
  @include pc {
    margin-bottom: 80px;
  }
  &.inquiry {
    .phishing_desc {
      .title {
        &:before {
          display: none;
        }
      }
      > p {
        margin-top: 24px;
        font-weight: 300;
        font-size: 16px;
        @include pc {
          margin-top: 16px;
          font-size: 14px;
        }
      }
    }
  }
}
.phishing_desc {
  .title {
    display: block;
    font-weight: 700;
    font-size: 32px;
    @include pc {
      font-size: 24px;
    }
    &:before {
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      margin: 0 auto;
      @include icoSet;
      background-image: url($assetsImg + "icon_pointer.png");
    }
  }
  > span {
    font-size: 16px;
    @include pc {
      font-size: 14px;
    }
  }
}
.phishing_swiper {
  overflow: hidden;
  margin-top: 70px;
  @include pc {
    margin-top: 24px;
  }
  .img {
    overflow: hidden;
    width: 100%;
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 50px;
    height: 50px;
    margin-top: -25px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    @include pc {
      width: 40px;
      height: 40px;
      margin-top: -20px;
    }
    &:after {
      content: "";
      width: 16px;
      height: 16px;
      border-left: 4px solid $white;
      border-bottom: 4px solid $white;
      @include pc {
        width: 12px;
        height: 12px;
        border-left-width: 2px;
        border-bottom-width: 2px;
      }
    }
  }
  .swiper-button-prev {
    left: 8px;
    &:after {
      margin-left: 4px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
  .swiper-button-next {
    right: 8px;
    &:after {
      margin-left: -4px;
      transform: rotate(225deg);
      -webkit-transform: rotate(225deg);
    }
  }
  .phishing_slide {
    a,
    button {
      display: block;
      width: 100%;
      text-align: left;
    }
    .phishing_slide_innerlist {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 12px;
    }
  }
  &.new {
    &.pc {
      display: block;
      @include pc {
        display: none;
      }
    }
    &.tablet {
      display: none;
      @include pc {
        display: block;
      }
      @include tablet {
        display: none;
      }
    }
    &.mobile {
      display: none;
      @include tablet {
        display: block;
      }
    }
    .phishing_slide {
      &:hover {
        .detail_hover {
          visibility: visible;
          opacity: 1;
        }
      }
    }
    .img {
      overflow: hidden;
      height: 476px;
      @include pos($p: relative);
      margin-bottom: 16px;
      border-radius: 16px;
      @include pc {
        height: 418px;
        margin-bottom: 10px;
      }
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 112px;
        @include pos($p: absolute, $b: 0);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 71.87%, #ffffff 100%);
      }
    }
    .title {
      @include pos($p: relative);
      @include elip("line2");
      line-height: 1.5;
      font-weight: 700;
      font-size: 20px;
      @include pc {
        font-size: 16px;
      }
      &.new {
        padding-left: 66px;
        &:before {
          content: "NEW";
          display: inline-block;
          @include pos($p: absolute, $l: 0, $t: 2px);
          padding: 2px 14px;
          font-weight: 500;
          font-size: 14px;
          text-align: center;
          color: $white;
          background-color: $red-color1;
          border-radius: 17px;
          @include pc {
            @include pos($t: 0);
          }
        }
      }
    }
    .detail_hover {
      visibility: hidden;
      opacity: 0;
      display: flex;
      @include pos($p: absolute, $l: 0, $r: 0, $t: 0, $b: 0);
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1;
      transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
      i {
        display: inline-block;
        padding: 8px 46px;
        text-align: center;
        font-weight: 700;
        font-size: 20px;
        border: 1px solid $white;
        color: $white;
        border-radius: 24px;
      }
    }
  }
}
.phishing_inquiry {
  .img {
    display: inline-block;
    margin: 36px 0 60px;
    @include pc {
      width: 280px;
      margin: 40px 0;
    }
  }
  .phishing_inquiry_btn {
    > a {
      display: inline-block;
      padding: 11px 68px;
      text-align: center;
      font-weight: 500;
      font-size: 28px;
      color: $main-color1;
      border: 3px solid $main-color1;
      border-radius: 50px;
      @include pc {
        padding: 6px 28px;
        font-size: 16px;
        border-width: 2px;
        border-radius: 28px;
      }
      &:after {
        content: "";
        display: inline-block;
        width: 10px;
        height: 20px;
        margin-left: 27px;
        @include icoSet;
        background-image: url($assetsImg + "phishing_inquiry_btn.png");
        @include pc {
          width: 5px;
          height: 10px;
          margin: -2px 0 0 4px;
        }
      }
    }
  }
}
.phishing_detail_conts {
  @include pos($p: relative);
  max-width: 536px;
  margin: 0 auto;
  @include mobile {
    max-width: none;
  }
  .detail_tit {
    text-align: center;
    @include pc {
      margin-bottom: 12px;
    }
    > * {
      display: block;
    }
    > span {
      font-size: 18px;
      @include pc {
        font-size: 14px;
      }
    }
    > strong {
      margin-top: 12px;
      font-weight: 700;
      font-size: 36px;
      @include pc {
        font-size: 24px;
      }
    }
  }
  .phishing_share_wrap {
    margin: 45px 0;
    @include pc {
      @include pos($p: sticky, $l: 0, $r: 0, $b: 0);
      padding: 16px;
      margin: 0;
    }
    div {
      & + div {
        margin-left: 16px;
        @include pc {
          margin-left: 0;
        }
      }
    }
  }
  .detail_desc {
    * {
      font-size: 16px !important;
      @include pc {
        font-size: 14px !important;
      }
    }
  }
}
.phishing_detail_other {
  margin-top: 110px;
  padding-top: 110px;
  text-align: center;
  border-top: 1px solid $blue-gray-30;
  @include pc {
    margin-top: 75px;
    padding-top: 35px;
  }
  > strong {
    font-weight: 700;
    font-size: 36px;
    @include pc {
      font-size: 24px;
    }
  }
}
.phishing_recent_list {
  margin-top: 70px;
  @include pc {
    margin-top: 0;
  }
  > ul {
    margin: -8px;
    font-size: 0;
    @include pc {
      margin: 0;
    }
    li {
      display: inline-block;
      width: 33.3%;
      vertical-align: top;
      padding: 8px;
      @include pc {
        display: block;
        width: 100%;
        padding: 0;
        margin-top: 30px;
      }
      > a,
      > button {
        text-align: left;
      }
    }
    .img {
      overflow: hidden;
      width: 100%;
      height: 104px;
      margin-bottom: 24px;
      border-radius: 16px;
      @include pc {
        height: 92px;
        margin-bottom: 12px;
      }
    }
    .title {
      @include elip("line2");
      font-weight: 700;
      font-size: 20px;
      @include pc {
        font-size: 16px;
      }
    }
  }
}
