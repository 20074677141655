.guide_wrap {
  > .subpage_info {
    margin: 25px 0 40px;
    @include pc {
      margin: 28px 0 23px;
    }
  }
}
.guide_caller {
  text-align: center;
  > div {
    display: inline-block;
    padding: 24px 65px;
    background-color: $white;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
    text-align: left;
    @include pc {
      display: block;
      padding: 30px 16px;
    }
    > * {
      display: inline-block;
      vertical-align: middle;
      @include pc {
        display: block;
      }
    }
  }
  .caller {
    width: 142px;
    height: 142px;
    border-radius: 50%;
    @include icoSet;
    background-color: $sub-color4;
    background-image: url($assetsImg + "icon_call.png");
    @include pc {
      width: 96px;
      height: 96px;
      margin: 0 auto;
    }
  }
  .call_list {
    margin-left: 64px;
    @include pc {
      margin: 20px 0 0;
    }
    li {
      font-size: 20px;
      @include pc {
        font-size: 14px;
      }
      & + li {
        margin-top: 12px;
        @include pc {
          margin-top: 20px;
        }
      }
    }
    strong {
      display: inline-block;
      margin-right: 24px;
      min-width: 170px;
      font-weight: 700;
      @include pc {
        display: block;
        margin: 4px 0 0 0;
        min-width: auto;
      }
    }
  }
}
.guide_respond {
  > h4 {
    margin: 96px 0 32px;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    @include pc {
      margin: 35px 0 20px;
      font-size: 20px;
    }
  }
}
.guide_respond_conts {
  padding: 100px 0;
  border-top: 1px solid $blue-gray-20;
  border-bottom: 1px solid $blue-gray-20;
  @include pc {
    padding: 40px 0;
  }
  .respond_cont {
    @include pos($p: relative);
    margin-bottom: 40px;
    padding-left: 187px;
    padding-bottom: 60px;
    @include pc {
      padding-left: 0;
      margin-bottom: 36px;
    }
    &.show {
      &:after {
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        margin-left: -12px;
        @include pos($p: absolute, $l: 50%, $b: 0);
        border-left: 2px solid $blue-gray-20;
        border-bottom: 2px solid $blue-gray-20;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }
  }
  .respond_icon {
    width: 132px;
    height: 132px;
    @include pos($p: absolute, $l: 0, $t: 0);
    border-radius: 50%;
    background-color: $sub-color4;
    @include icoSet;
    @include pc {
      width: 68px;
      height: 68px;
      margin: 0 auto;
      @include pos($p: static);
    }
    &.icon1 {
      background-image: url($assetsImg + "guide_sub_img1.png");
    }
    &.icon2 {
      background-image: url($assetsImg + "guide_sub_img2.png");
    }
    &.icon3 {
      background-image: url($assetsImg + "guide_sub_img3.png");
    }
    &.icon4 {
      background-image: url($assetsImg + "guide_sub_img4.png");
    }
  }
  .respond_desc {
    @include pc {
      margin-top: 30px;
    }
    > strong {
      font-weight: 700;
      font-size: 24px;
      @include pc {
        font-size: 18px;
      }
      & + p {
        margin-top: 24px;
        @include pc {
          margin-top: 15px;
        }
      }
    }
    p,
    pre {
      line-height: 1.5;
      font-size: 18px;
      @include pc {
        font-size: 14px;
      }
    }
  }
  .respond_way,
  .respond_call {
    margin-top: 20px;
    @include pc {
      margin-top: 16px;
    }
    > strong {
      font-weight: 500;
      font-size: 20px;
      @include pc {
        font-size: 18px;
      }
      & + * {
        margin-top: 10px;
      }
    }
  }
  .respond_call {
    pre {
      margin-top: 28px;
      @include pc {
        margin-top: 16px;
      }
    }
  }
  .bank_call_selector {
    display: flex;
    align-items: center;
    justify-items: center;
    @include pc {
      display: block;
    }
    > * {
      height: 40px;
      flex: 1;
      font-weight: 700;
      text-align: center;
      @include pc {
        width: 100%;
        display: block;
      }
    }
    .bank_callnumber {
      padding: 8px 0;
      background-color: $gray-10;
      font-size: 16px;
    }
    .bank_choice {
      @include pos($p: relative);
      padding: 7px 0;
      background-color: $white;
      box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.12);
      font-size: 18px;
      z-index: 1;
      &:after {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 12px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 12px solid #272e40;
      }
    }
  }
}

.bank_item {
  > button {
    display: block;
    width: 100%;
  }
  .bank_name {
    display: block;
    margin-top: 20px;
    font-weight: 700;
    font-size: 12px;
  }
  .bank {
    width: 44px;
    height: 44px;
    margin: 0 auto;
    @include icoSet;
    &.ibk {
      background-image: url($assetsImg + "ibk.png");
    }
    &.kb {
      background-image: url($assetsImg + "kb.png");
    }
    &.kdb {
      background-image: url($assetsImg + "kdb.png");
    }
    &.nonghyop {
      background-image: url($assetsImg + "nonghyop.png");
    }
    &.sc {
      background-image: url($assetsImg + "sc.png");
    }
    &.sh {
      background-image: url($assetsImg + "sh.png");
    }
    &.shinhan {
      background-image: url($assetsImg + "shinhan.png");
    }
    &.shinhyup {
      background-image: url($assetsImg + "shinhyup.png");
    }
    &.wori {
      background-image: url($assetsImg + "wori.png");
    }
    &.hana {
      background-image: url($assetsImg + "hana.png");
    }
    &.citi {
      background-image: url($assetsImg + "citi.png");
    }
    &.kakao {
      background-image: url($assetsImg + "kakao.png");
    }
    &.k {
      background-image: url($assetsImg + "k.png");
    }
    &.toss {
      background-image: url($assetsImg + "toss.png");
    }
    &.gyongnam {
      background-image: url($assetsImg + "gyongnam.png");
    }
    &.busan {
      background-image: url($assetsImg + "busan.png");
    }
    &.daegu {
      background-image: url($assetsImg + "daegu.png");
    }
    &.kwangju {
      background-image: url($assetsImg + "kwangju.png");
    }
    &.saemaeul {
      background-image: url($assetsImg + "saemaeul.png");
    }
    &.ucheguk {
      background-image: url($assetsImg + "ucheguk.png");
    }
    &.jeonbuk {
      background-image: url($assetsImg + "jeonbuk.png");
    }
    &.jeju {
      background-image: url($assetsImg + "jeju.png");
    }
    &.localnonghyop {
      background-image: url($assetsImg + "localnonghyop.png");
    }
  }
}
