.used_wrap,
.personal_wrap {
  margin-top: 38px;
  padding: 24px 16px 32px;
  background-color: $gray-10;
  border-radius: 6px;
  @include pc {
    margin-top: 24px;
  }
  > pre {
    white-space: pre-line;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    @include pc {
      font-size: 12px;
    }
  }
}

.terms_table {
  border-left: 1px solid $gray-30;
  border-top: 1px solid $gray-30;
  th,
  td {
    padding: 10px;
    border-right: 1px solid $gray-30;
    border-bottom: 1px solid $gray-30;
    font-size: 14px;
    @include pc {
      padding: 5px;
      font-size: 12px;
    }
  }
  th {
    background-color: $gray-20;
  }
  td {
    vertical-align: middle;
    text-align: center;
  }
}
