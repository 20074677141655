.forms_wrap {
  margin-top: 55px;
  padding: 100px 200px;
  border-top: 1px solid $blue-gray-20;
  border-bottom: 1px solid $blue-gray-20;
  @include pc {
    margin-top: 24px;
    padding: 40px 0;
  }
  .cont_btns_wrap {
    margin-top: 50px;
    padding: 0 100px;
    @include pc {
      margin-top: 40px;
      padding: 0;
    }
    .alert {
      display: block;
      margin-top: 20px;
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      @include pc {
        margin-top: 12px;
        font-size: 12px;
      }
    }
  }
}
