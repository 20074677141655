
@import "styles/color.scss";
@import "styles/mixin.scss";

.forms_wrap_refund {
    margin-top: 55px;
    padding: 40px 300px;
    border-top: 1px solid $blue-gray-20;

    @media all and (min-width: 1200px) {
      border-bottom: 1px solid $blue-gray-20;
    }
  
    > section {
        margin-top: 32px;
        
        @include mobile {
          margin-top: 24px;
        }
    }


    > section > div > div > span {
        color:  #000;
        font-family: "Noto Sans KR";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;

        @include mobile {
          font-size: 12px;
        }
    }
    

    button {
        color: #FFF;
        text-align: center;
        font-family: "Noto Sans KR";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @media all and (max-width: 1200px) {
          font-size: 16px;
          line-height: inherit;
        }
        @include mobile {
          display: block;
        }
    }

    label {
        color: red;
    }
    
    .refund_guide_section {
        margin-top: 60px;
        &:first-child {
            margin-top: 0;
        }
        @media all and (max-width: 1200px) {
            margin-top: 36px;
        }

        .refund_guide_text_container {
            padding: 20px;
        
            border-radius: 16px;
            background: #F2F2F2;
        
            flex: 1 0 0;
        
            color: var(--gray-70, #404040);
            font-family: "Noto Sans KR";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        
            .title {
              color: var(--Black, #000);
              font-family: "Noto Sans KR";
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }
            .warning {
              color: #F00;
              font-family: "Noto Sans KR";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
    }
    .refund_caution {
      color: #E94949;
      text-align: center;
      font-family: "Noto Sans KR";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      
      @include mobile {
        font-size: 10px;
      }
    }
    
    @include pc {
      margin-top: 42px;
      padding: 40px 0;

    }
    
    @include mobile {
      margin-top: 29px;
      padding: 30px 0 0 0;
    }
    .cont_btns_wrap {
      margin: 36px 0;
      padding: 0;
      
      @include mobile {
        margin-top: 34px;
        margin-bottom: 24px;
      }

      .alert {
        display: block;
        margin-top: 20px;
        text-align: center;
        font-weight: 500;
        font-size: 18px;
        @include pc {
          margin-top: 12px;
          font-size: 12px;
        }
      }
    }
  }
  