html,
body {
  overflow: auto;
  height: 100%;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 12px;
  color: $black;
  letter-spacing: -0.7px;
  line-height: 1.3;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  * {
    font-family: "Noto Sans KR", sans-serif;
    font-size: inherit;
    color: $black;
    letter-spacing: -0.7px;
    line-height: 1.3;
    box-sizing: border-box;
  }
  &.layer_opened {
    overflow: hidden;
  }
}
select,
input,
textarea,
button {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  border-radius: 0;
  outline: none;
  appearance: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    outline: none;
  }
}
a {
  text-decoration: none;
}
button {
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
}
textarea {
  resize: none;
}
img {
  max-width: 100%;
  width: 100%;
  vertical-align: top;
}
pre {
  white-space: pre-wrap;
  word-break: break-all;
}
table {
  width: 100%;
}
.hide {
  overflow: hidden;
  display: block;
  width: 0;
  height: 0;
  font-size: 0;
  clip: clip(-1px, -1px, -1px, -1px);
  @include pos($p: absolute, $l: -9999px);
}
.img {
  display: block;
}
.u {
  text-decoration: underline;
}
.alert {
  color: $red-color1 !important;
}
.break_pc_off {
  display: block !important;
  @include pc {
    display: none !important;
  }
}
.break_pc_on {
  display: none !important;
  @include pc {
    display: block !important;
  }
}
.break_tablet_off {
  display: block !important;
  @include tablet {
    display: none !important;
  }
}
.break_tablet_on {
  display: none !important;
  @include tablet {
    display: block !important;
  }
}
.break_mobile_off {
  display: block !important;
  @include mobile {
    display: none !important;
  }
}
.break_mobile_on {
  display: none !important;
  @include mobile {
    display: block !important;
  }
}
.grecaptcha-badge {
  visibility: hidden;
}
#footer {
  background-color: $sub-color4;
  * {
    color: $white;
  }
}
.footer_wrap {
  padding: 80px 0;
  text-align: center;
  @include pc {
    padding: 60px 0;
  }
  ul {
    font-size: 0;
    li {
      display: inline-block;
      @include pos($p: relative);
      padding: 0 10px;
      vertical-align: middle;
      @include pc {
        padding: 0 5px;
      }
      &:first-child {
        &:before {
          display: none;
        }
      }
      &:before {
        content: "";
        width: 1px;
        height: 80%;
        @include pos($p: absolute, $l: 0, $t: 50%);
        background-color: $white;
        transform: translateY(-50%);
      }
    }
    button,
    a {
      vertical-align: middle;
      font-size: 18px;
      @include pc {
        font-size: 12px;
      }
    }
  }
  p {
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: 0;
    @include pc {
      font-size: 10px;
    }
    &:before {
      content: "";
      display: block;
      width: 256px;
      height: 64px;
      margin: 48px auto 33px;
      @include icoSet;
      background-image: url($assetsImg + "footer_logo.png");
      @include pc {
        width: 200px;
        height: 50px;
        margin: 60px auto 40px;
      }
    }
  }
}
#header {
  width: 100%;
  @include pos($p: fixed, $l: 0, $r: 0, $t: 0);
  background-color: $white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  z-index: 700;
}
.header_wrap {
  display: flex;
  @include pos($p: relative);
  padding: 0 32px;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  @include pc {
    padding: 12px 16px;
  }
  .logo {
    width: 156px;
    height: 28px;
    @include pc {
      width: 150px;
      height: 28px;
    }
    a {
      display: block;
      width: 100%;
      height: 100%;
      @include icoSet;
      background-image: url($assetsImg + "header_logo.png");
    }
  }
}
.nav_wrap {
  .dep1_list,
  .dep2_list {
    font-size: 0;
  }
  .dep1 {
    display: inline-block;
    vertical-align: middle;
    margin-left: 40px;
    cursor: pointer;
    &.entered {
      .dep2 {
        @include pos($t: calc(100% - 2px));
        z-index: 1;
        opacity: 1;
        transition: top ease-in-out 0.2s, opacity ease-in-out 0.6s;
      }
    }
    &:first-child {
      margin-left: 0;
    }
    &:nth-of-type(2) {
      .dep2_list {
        margin-right: 124px;
      }
    }
    &:nth-of-type(3) {
      .dep2_list {
        margin-right: 174px;
      }
    }
    &:last-child {
      margin-left: 30px;
      .dep1_tit {
        margin: 10px 0;
        padding: 6px 30px;
        color: $white;
        background-color: $sub-color4;
        border-radius: 6px;
        &:after {
          content: "NEW";
          display: block;
          @include pos($p: absolute, $r: -6px, $t: -6px);
          padding: 2px 8px;
          font-weight: 700;
          font-size: 10px;
          text-align: center;
          color: $white;
          background-color: $red-color1;
          border-radius: 8px;
        }
      }
    }
  }
  .dep1_tit {
    display: block;
    @include pos($p: relative);
    padding: 16px 0;
    font-weight: 500;
    font-size: 16px;
  }
  .dep2 {
    overflow: hidden;
    width: 100%;
    @include pos($p: absolute, $l: 0, $r: 0, $t: 0);
    background-color: $sub-color4;
    z-index: -1;
    opacity: 0;
    transition: top ease-in-out 0.6s, opacity ease-in-out 0.2s;
  }
  .dep2_list {
    text-align: right;
    > li {
      display: inline-block;
      margin-left: 40px;
      vertical-align: middle;
      &:first-child {
        margin-left: 0;
      }
    }
    a {
      display: block;
      padding: 16px 0;
      color: $white;
      font-size: 16px;
    }
  }
}
.mo_nav {
  display: flex;
  align-items: center;
  .dep1_cancel {
    margin-right: 16px;
    font-size: 14px;
    text-decoration: underline;
  }
}
.mo_nav_wrap {
  &.active {
    .mo_nav_burger {
      i {
        @include pos($t: 50%);
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(1) {
          transform: rotate(45deg);
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
        }
      }
    }
    .mo_nav_deps {
      visibility: visible;
      transition: visibility 0.4s ease-in-out;
      .mo_nav_menu {
        transition: right 0.4s ease-in-out;
        right: 0;
      }
    }
  }
}
.mo_nav_burger {
  width: 40px;
  height: 40px;
  @include pos($p: relative);
  padding: 10px 8px;
  background-color: $white;
  border-radius: 6px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.16);
  z-index: 2;
  > span {
    display: block;
    width: 100%;
    height: 100%;
    @include pos($p: relative);
  }
  i {
    display: block;
    width: 100%;
    height: 2px;
    @include pos($p: absolute);
    margin-top: -1px;
    background-color: $sub-color4;
    border-radius: 2px;
    transition: top 0.4s ease-in-out, transform 0.4s ease-in-out;
    &:nth-child(1) {
      @include pos($t: 0);
    }
    &:nth-child(2) {
      @include pos($t: 50%);
    }
    &:nth-child(3) {
      @include pos($t: 100%);
    }
  }
}
.mo_nav_deps {
  visibility: hidden;
  width: 100%;
  height: 100%;
  @include pos($p: fixed, $l: 0, $r: 0, $t: 0, $b: 0);
  transition: visibility 0.4s ease-in-out;
  .mo_nav_menu {
    overflow: hidden;
    width: $conts-mobile;
    height: 100%;
    @include pos($p: absolute, $r: -$conts-mobile, $t: 0, $b: 0);
    padding-top: 72px;
    background-color: $white;
    z-index: 1;
    transition: right 0.4s ease-in-out;
    @include mobile {
      width: 360px;
      @include pos($r: -360px);
    }
    @include min {
      width: 100%;
      @include pos($r: -100%);
    }
  }
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    @include pos($p: absolute, $l: 0, $r: 0, $t: 0, $b: 0);
    background-color: rgba(0, 0, 0, 0.6);
  }
  .dep1_list {
    overflow-y: scroll;
    height: 100%;
    padding: 16px;
    -webkit-overflow-scrolling: touch;
    .dep1 {
      margin-top: 36px;
      &:first-child {
        margin-top: 0;
      }
      &.entered {
        .dep1_tit {
          &:after {
            margin-top: -4px;
            transform: rotate(-225deg);
          }
        }
        .dep2 {
          max-height: 999px;
          transition: max-height 0.9s ease-in-out;
        }
      }
    }
    .dep1_tit {
      display: block;
      @include pos($p: relative);
      width: 100%;
      font-weight: 500;
      font-size: 20px;
      text-align: left;
    }
    strong {
      &.dep1_tit {
        @include pos($p: relative);
        &:after {
          content: "";
          display: block;
          width: 12px;
          height: 12px;
          margin-top: -12px;
          @include pos($p: absolute, $r: 2px, $t: 50%);
          border-left: 2px solid $blue-gray-20;
          border-bottom: 2px solid $blue-gray-20;
          transform: rotate(-45deg);
        }
      }
    }
    .dep1_phishing {
      padding: 6px;
      text-align: center;
      border-radius: 6px;
      color: $white;
      background-color: $sub-color4;
      &:after {
        content: "NEW";
        display: block;
        @include pos($p: absolute, $r: -6px, $t: -6px);
        padding: 2px 8px;
        font-weight: 500;
        font-size: 10px;
        text-align: center;
        color: $white;
        background-color: $red-color1;
        border-radius: 8px;
      }
    }
    .dep2 {
      overflow: hidden;
      max-height: 0;
      transition: max-height 0.3s ease-in-out;
    }
    .dep2_list {
      li {
        margin-top: 24px;
        button,
        a {
          display: block;
          width: 100%;
          font-weight: 500;
          font-size: 16px;
          color: $blue-gray-30;
          text-align: left;
        }
      }
    }
  }
}
.app_down {
  > a {
    display: block;
    padding: 16px 40px;
    font-weight: 700;
    font-size: 24px;
    background-color: $white;
    border-radius: 6px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
    text-align: center;
    &:before {
      content: "";
      display: inline-block;
      width: 24px;
      height: 24px;
      margin: -4px 8px 0 0;
      vertical-align: middle;
      @include icoSet;
    }
    &.google {
      &:before {
        background-image: url($assetsImg + "icon_google.png");
      }
    }
    &.ios {
      &:before {
        background-image: url($assetsImg + "icon_ios.png");
      }
    }
  }
}
.cont_btns_wrap {
  margin-top: 80px;
  padding: 0 300px;
  @include pc {
    margin-top: 40px;
    padding: 0;
  }
  > button {
    width: 100%;
  }
  .alert-wrap{
    margin-top: 20px;
    background-color: #fff0f0;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 4px;
    ul{
      display: flex;
      flex-flow: column;
      gap: 8px;
      li{
        color: $red-color1 !important;
        font-size: 14px;
        font-weight: 500;
        word-break: keep-all;
        &.bold{
          font-weight: 600;
        }
        @media (max-width:1200px) {
          font-size: 12px;
        }
      }
    }
  }
}
.react-datepicker {
  border-color: $blue-gray-20;
  border-radius: 0;
  &__header {
    background-color: $gray-10;
    border-radius: 0;
    border-bottom-color: $blue-gray-20;
    &:not(.react-datepicker__header--has-time-select) {
      border-top-right-radius: 0;
    }
  }
  &__navigation-icon {
    &:before {
      width: 8px;
      height: 8px;
      border-width: 2px 2px 0 0;
    }
  }
  &__day {
    border-radius: 0;
    &:hover,
    &:focus {
      background-color: $blue-gray-10;
      border-radius: 0;
    }
  }
  &__day--selected {
    background-color: $main-color1;
    &:hover,
    &:focus {
      background-color: $main-color1;
      border-radius: 0;
    }
  }
}
