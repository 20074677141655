.main {
  overflow: hidden;
  .linkto_button {
    display: inline-block;
    padding: 10px 72px;
    font-weight: 500;
    font-size: 28px;
    color: $main-color1;
    border: 2px solid $main-color1;
    border-radius: 50px;
    @include pc {
      padding: 6px 27px;
      font-size: 16px;
      border-radius: 27px;
    }
    &:after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 10px;
      height: 10px;
      margin: -5px 0 0 16px;
      border-left: 2px solid $main-color1;
      border-bottom: 2px solid $main-color1;
      transform: rotate(225deg);
      @include pc {
        width: 5px;
        height: 5px;
        margin: -2px 0 0 2px;
      }
    }
  }
}
.main_sec_wrap {
  background-color: $white;
  &.sec_intro {
    background-color: #edf3ff;
  }
  &.sec_stop,
  &.sec_news {
    background-color: $sub-color4;
  }
}
.wrap_wide,
.wrap_conts {
  margin: 0 auto;
}
.wrap_wide {
  max-width: $conts-wide;
  @include wide {
    max-width: none;
  }
  @include pc {
    max-width: $conts-mobile;
  }
  @include mobile {
    max-width: none;
  }
}
.wrap_conts {
  max-width: $conts-pc;
  @include pc {
    max-width: $conts-mobile;
  }
}
.sec_intro {
  padding: 146px 0 60px;
  @include pc {
    padding: 116px 0;
  }
  > .wrap_wide {
    padding: 0 156px;
    @include pc {
      padding: 0 16px;
      text-align: center;
    }
    > section {
      @include pos($p: relative);
      @include pc {
        display: inline-block;
      }
    }
  }
  .main_intro_desc {
    display: inline-block;
    @include pos($p: relative);
    z-index: 2;
    @include pc {
      display: block;
    }
    > .desc {
      padding: 120px 0 160px;
      background: linear-gradient(90deg, #edf3ff 0%, #edf3ff 70.21%, rgba(237, 243, 255, 0) 93.61%);
      @include pc {
        padding: 0;
        background: transparent;
      }
    }
    h4 {
      margin: 0 auto 50px;
      @include pc {
        width: 316px;
        margin: 0 auto 24px;
      }
    }
  }
  .intro_func_list {
    display: grid;
    grid-template-columns: repeat(4, 115px);
    gap: 30px;
    @include pc {
      grid-template-columns: repeat(4, 64px);
      gap: 20px;
    }
    > li {
      overflow: hidden;
      border-radius: 16px;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
      border: 2px solid transparent;
      background-image: linear-gradient(to bottom, #00cdff 0%, #00e4c9 100%);
      @include pc {
        border-radius: 8px;
      }
      strong {
        display: block;
        width: 100%;
        height: 100%;
        padding: 20px;
        text-align: center;
        font-weight: 500;
        font-size: 18px;
        color: $sub-color4;
        background-color: $white;
        @include pc {
          padding: 10px 9px;
          font-size: 12px;
        }
      }
    }
  }
  .app_down {
    > a {
      display: inline-block;
      min-width: 256px;
      & + a {
        margin-left: 30px;
      }
    }
  }
  .main_intro_img {
    @include pos($p: absolute, $r: 0, $t: 0);
    margin-top: 75px;
    @include pc {
      width: 298px;
      margin: 52px auto 0;
      @include pos($p: relative);
    }
    .img {
      &:not(.frame) {
        @include pos($p: absolute);
        @include icoSet;
      }
    }
    .shield {
      width: 80px;
      height: 50px;
      @include pos($l: 192px, $t: 37px);
      background-image: url($assetsImg + "main_intro_shield.png");
      @include animateLoop(updown, 2);
      @include pc {
        width: 30px;
        height: 20px;
        @include pos($l: 72px, $t: 14px);
      }
    }
    .balloon {
      width: 90px;
      height: 80px;
      @include pos($l: 312px, $t: -9px);
      background-image: url($assetsImg + "main_intro_balloon.png");
      @include animateLoop(updown, 2);
      @include pc {
        width: 34px;
        height: 31px;
        @include pos($l: 118px, $t: 0px);
      }
    }
    .lock {
      width: 92px;
      height: 111px;
      @include pos($r: 110px, $b: 310px);
      background-image: url($assetsImg + "main_intro_lock.png");
      z-index: 1;
      @include animateLoop(updown, 2);
      @include pc {
        width: 35px;
        height: 42px;
        @include pos($r: 42px, $b: 110px);
      }
    }
    .gradient {
      width: 150px;
      height: 180px;
      @include pos($r: 82px, $b: 280px);
      background-image: url($assetsImg + "main_intro_gradient.png");
      @include animateLoop(fade, 2);
      @include pc {
        width: 57px;
        height: 70px;
        @include pos($r: 31px, $b: 105px);
      }
    }
    .phone {
      width: 47px;
      height: 114px;
      @include pos($l: 190px, $b: 160px);
      background-image: url($assetsImg + "main_intro_phone.png");
      @include animateLoop(updown, 2);
      @include pc {
        width: 17px;
        height: 44px;
        @include pos($l: 72px, $b: 58px);
      }
    }
    .like {
      width: 50px;
      height: 54px;
      @include pos($l: 330px, $b: 125px);
      background-image: url($assetsImg + "main_intro_like.png");
      @include animateLoop(updown, 2);
      @include pc {
        width: 18px;
        height: 21px;
        @include pos($l: 125px, $b: 40px);
      }
    }
    .location {
      width: 95px;
      height: 73px;
      @include pos($l: 235px, $b: 70px);
      background-image: url($assetsImg + "main_intro_location.png");
      @include animateLoop(updown, 2);
      @include pc {
        width: 36px;
        height: 28px;
        @include pos($l: 99px, $b: 22px);
      }
    }
  }
}
.sec_phishing {
  padding: 86px 0 53px;
  @include tablet {
    padding: 24px 0 48px;
  }
  .wrap_conts {
    text-align: center;
  }
  .phishing_wrap {
    margin-bottom: 44px;
    @include tablet {
      padding: 0 20px;
    }
  }
}
.sec_video {
  padding: 20px 0 176px;
  @include pc {
    padding: 0;
  }
  .video_wrap{
    width: 100%;
    display: flex;
    justify-content: space-between;
  .video_frame {
    width: 940px;
    height: 511px;
    @include pos($p: relative);
    padding-bottom: 50%;
    >p{
      font-weight: 500;
      font-size: 20px;
      line-height: 29px;
      margin-top: -35px;
    }
    iframe {
      @include pos($p: absolute, $l: 0, $r: 0, $t: 0, $b: 0);
    }
  }
    .video_list{
      width: 240px;
      >img{
        width: 240px;
        cursor: pointer;
        margin-bottom: 16px;
      }
      .active{
        border: 4px solid #3DC289;
      }
      .default{
        border: 4px solid white;
        margin-bottom: 16px;
        &:hover{
          border: 4px solid #3DC289;
       }
      }

    }
    @include pc {
      width: 100%;
      max-width: 540px;
      margin-top: 36px;
      display: block;
      .video_frame{
        width: 100%;
        height: 215px;
      }
      .video_list{
        width: 100%;
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        >img{
          width: 49%;
          &:hover{

            border: 4px solid #3DC289;
          }
        }
      }
    }
  }
}
.sec_award {
  .main_award_wrap {
    font-size: 0;
    @include pc {
      display: flex;
      flex-direction: column;
    }
    &:first-child {
      flex-direction: column-reverse;
      .award_img {
        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          @include pos($p: absolute, $l: 0, $r: 0, $t: 0, $b: 0);
          background-color: rgba(46, 147, 111, 0.7);
        }
      }
    }
    > * {
      display: inline-block;
      width: 50%;
      vertical-align: middle;
      text-align: center;
      @include pc {
        display: block;
        width: 100%;
      }
    }
  }
  .award_desc {
    padding: 0 30px;
    background-color: $white;
    @include pc {
      padding: 80px 30px;
    }
    > * {
      display: block;
    }
    i {
      margin-bottom: 60px;
      letter-spacing: 16px;
      font-weight: 300;
      font-size: 24px;
      @include pc {
        margin-bottom: 12px;
        letter-spacing: 8px;
        font-size: 12px;
      }
    }
    span {
      font-size: 36px;
      @include pc {
        font-size: 20px;
      }
    }
    strong {
      display: inline-block;
      font-weight: 700;
      font-size: 56px;
      @include pc {
        font-size: 24px;
      }
      small {
        display: block;
        margin-top: 10px;
        font-weight: 500;
        font-size: 20px;
        text-align: right;
        @include pc {
          margin-top: 5px;
          font-size: 12px;
        }
      }
    }
  }
  .award_img {
    @include pos($p: relative);
    .award_explain {
      width: 100%;
      @include pos($p: absolute, $t: 50%);
      transform: translateY(-50%);
      z-index: 1;
      @include pc {
        padding: 0 30px;
      }
      * {
        color: $white;
      }
      strong {
        font-weight: 700;
        font-size: 72px;
        @include mobile {
          font-size: 40px;
        }
        &:before {
          content: "";
          display: block;
          width: 55px;
          height: 72px;
          margin: 0 auto;
          @include icoSet;
          background-image: url($assetsImg + "main_award_logo.png");
          @include mobile {
            width: 32px;
            height: 42px;
          }
        }
      }
      p {
        margin-top: 96px;
        line-height: 1.5;
        font-size: 18px;
        @include mobile {
          margin-top: 26px;
          font-size: 14px;
          > br {
            display: none;
          }
        }
      }
    }
  }
}
.sec_news {
  .main_news_wrap {
    @include pos($p: relative);
    > * {
      display: inline-block;
      vertical-align: middle;
      @include tablet {
        display: block;
      }
    }
  }
  h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33%;
    height: 100%;
    padding-left: 120px;
    @include wide {
      padding-left: 60px;
    }
    @include pc {
      width: 100%;
      padding: 40px 0;
      text-align: center;
    }

    > a {
      display: block;
      * {
        display: block;
        color: $white;
      }
    }
    strong {
      font-weight: 500;
      font-size: 64px;
      @include pc {
        font-size: 32px;
      }
    }
    span {
      margin-top: 28px;
      font-size: 24px;
      @include pc {
        margin-top: 7px;
        font-size: 21px;
      }
      @include tablet {
        font-size: 14px;
      }
    }
  }
  .news_list {
    width: 67%;
    @include pc {
      width: 100%;
    }
    > li {
      display: inline-block;
      width: 33.3%;
      vertical-align: top;
      @include mobile {
        width: 50%;
      }
    }
    a {
      display: block;
      width: 100%;
      height: 100%;
      @include pos($p: relative);
      text-align: left;
      &:hover {
        &:after {
          opacity: 0.86;
          transition: opacity 0.4s ease-in-out;
        }
      }
      &:after {
        content: "";
        display: block;
        @include pos($p: absolute, $l: 0, $r: 0, $t: 0, $b: 0);
        background-color: $black;
        opacity: 0.6;
        transition: opacity 0.4s ease-in-out;
      }
    }
  }
  .news_list_conts {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include pos($p: absolute, $l: 0, $r: 0, $t: 0, $b: 0);
    padding: 30px;
    z-index: 1;
    @include pc {
      padding: 13px;
    }
    .news_type,
    .news_tit {
      color: $white;
    }
    .news_type {
      @include pos($p: relative);
      padding-left: 40px;
      letter-spacing: 0;
      font-weight: 500;
      font-size: 18px;
      @include pc {
        padding-left: 20px;
        font-size: 12px;
      }
      &:before {
        content: "";
        display: block;
        width: 28px;
        height: 28px;
        @include pos($p: absolute, $l: 0, $t: 50%);
        margin-top: -14px;
        @include icoSet;
        @include pc {
          width: 14px;
          height: 14px;
          margin-top: -7px;
        }
      }
      &.news {
        &:before {
          background-image: url($assetsImg + "main_icon_news.png");
        }
      }
      &.blog {
        &:before {
          background-image: url($assetsImg + "main_icon_blog.png");
        }
      }
    }
    .news_tit {
      min-height: 52px;
      @include elip("line2");
      font-size: 18px;
      @include pc {
        min-height: 33px;
        font-size: 14px;
      }
    }
  }
}
.sec_func {
  padding: 150px 0 250px;
  text-align: center;
  @include pc {
    padding: 67px 0 127px;
  }
  > .wrap_conts {
    padding: 0 16px;
  }
  h4 {
    font-weight: 500;
    font-size: 64px;
    @include pc {
      font-size: 24px;
    }
    > strong {
      display: block;
      font-weight: 700;
      font-size: 80px;
      @include pc {
        font-size: 32px;
      }
    }
  }
  .func_tit {
    font-weight: 700;
    font-size: 36px;
    @include pc {
      font-size: 22px;
    }
  }
  p {
    line-height: 1.5;
    font-size: 16px;
    @include pc {
      font-size: 12px;
    }
  }
  section {
    margin-top: 86px;
    @include pc {
      margin-top: 58px;
    }
  }
  .main_func_wrap {
    .func_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 190px;
      @include pc {
        flex-direction: column-reverse;
        margin-top: 28px;
      }
      &:first-child {
        margin-top: 0;
      }
      &:nth-of-type(even) {
        flex-direction: row-reverse;
        @include pc {
          flex-direction: column-reverse;
        }
      }
      > * {
        flex: 1;
      }
      > .img {
        margin: 0 auto;
        opacity: 0;
        transform: translateY(70px);
        transition: opacity 0.6s ease, transform 0.6s ease;
        @include pc {
          width: 328px;
        }
        @include min {
          width: 100%;
        }
      }
      &.active {
        > .func_desc {
          > * {
            opacity: 1;
            transform: translateY(0);
          }
        }
        > .img {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
    .func_desc {
      text-align: left;
      @include pc {
        padding: 36px 16px 75px;
        text-align: center;
      }
      > * {
        display: block;
        opacity: 0;
        transform: translateY(16px);
        transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
        &:nth-child(2) {
          transition-delay: 0.4s;
        }
        &:nth-child(3) {
          transition-delay: 0.8s;
        }
      }
      .func_tit {
        margin: 36px 0 26px;
        @include pc {
          margin: 21px 0 14px;
        }
      }
      i {
        color: $main-color1;
        font-weight: 700;
        font-size: 24px;
        @include pc {
          font-size: 14px;
        }
      }
      p {
        font-weight: 300;
        color: $gray-70;
      }
    }
  }
  .main_func_case {
    p {
      margin: 26px 0 72px;
      font-weight: 300;
      font-size: 16px;
      @include pc {
        padding: 0 16px;
        margin: 15px 0 20px;
        font-size: 14px;
        > br {
          display: none;
        }
      }
    }
    .img {
      width: 787px;
      margin: 0 auto 90px;
      @include pc {
        width: 280px;
        margin-bottom: 40px;
      }
    }
  }
  .main_func_cooperate {
    .img {
      width: 960px;
      margin: 34px auto 0;
      @include pc {
        width: 328px;
        margin: 20px auto 0;
      }
      @include min {
        width: 100%;
      }
    }
  }
}
.sec_stop {
  padding: 360px 0;
  @include pc {
    padding: 165px 0;
  }
  > .wrap_conts {
    @include pc {
      padding: 0 16px;
    }
  }
  .main_stop_wrap {
    text-align: center;
    &.active {
      .stop_txt {
        opacity: 1;
        transition: opacity 0.6s ease;
        @for $i from 1 through 3 {
          &:nth-child(#{$i}) {
            transition-delay: 0.6 * ($i - 1) + s;
          }
        }
      }
    }
    &:before {
      content: "";
      display: block;
      width: 120px;
      height: 120px;
      margin: 0 auto 40px;
      @include icoSet;
      background-image: url($assetsImg + "main_hand.png");
      @include pc {
        width: 75px;
        height: 75px;
        margin: 0 auto 28px;
      }
    }
  }
  .stop_txt {
    opacity: 0;
    font-weight: 700;
    font-size: 64px;
    color: $white;
    transition: opacity 0.6s ease;
    @include pc {
      display: block;
      font-size: 28px;
      line-height: 1.5;
    }
    & + .stop_txt {
      margin-left: 8px;
      @include pc {
        margin-left: 0;
      }
    }
    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        transition-delay: 0.6 * ($i - 1) + s;
      }
    }
  }
}
.sec_ask {
  padding: 116px 0;
  @include pc {
    padding: 0;
  }
  > .wrap_conts {
    @include pc {
      padding: 0 16px;
    }
  }
  .main_ask_wrap {
    display: flex;
    justify-content: space-between;
    @include pc {
      display: block;
    }
    > section {
      width: 50%;
      @include pc {
        width: 100%;
        margin: 76px 0;
      }
    }
  }
  .ask_link {
    padding-top: 24px;
    @include pc {
      padding-top: 0;
      text-align: center;
    }
    h4 {
      margin-bottom: 50px;
      font-weight: 700;
      font-size: 52px;
      @include pc {
        margin-bottom: 14px;
        font-size: 25px;
      }
    }
  }
  .ask_link_list {
    @include clearfix;
    @include pc {
      display: inline-block;
      margin: -6px;
    }
    @include mobile {
      display: block;
    }
    > li {
      float: left;
      min-width: 187px;
      margin-left: 16px;
      @include pc {
        padding: 6px;
        margin-left: 0;
      }
      @include mobile {
        min-width: auto;
        width: 50%;
      }
      &:first-child {
        margin-left: 0;
      }
      a {
        display: block;
        width: 100%;
        padding: 8px 44px;
        text-align: center;
        color: $white;
        border-radius: 8px;
        font-size: 16px;
        @include mobile {
          padding: 8px 0;
        }
      }
      &.faq {
        a {
          background-color: $sub-color4;
        }
      }
      &.inquiry {
        a {
          background-color: $main-color1;
        }
      }
    }
  }
  .ask_notice_tit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    > a {
      color: $main-color1;
      text-decoration: underline;
      font-size: 18px;
      @include pc {
        font-size: 14px;
      }
    }
    > strong {
      @include pos($p: relative);
      padding-left: 45px;
      font-weight: 700;
      font-size: 20px;
      @include pc {
        padding-left: 37px;
        font-size: 16px;
      }
      &:before {
        content: "";
        display: block;
        width: 32px;
        height: 32px;
        @include pos($p: absolute, $l: 0, $t: 50%);
        margin-top: -16px;
        @include icoSet;
        background-image: url($assetsImg + "icon_main_notice.png");
        @include pc {
          width: 28px;
          height: 28px;
          margin-top: -14px;
        }
      }
    }
  }
  .ask_notice_list {
    border-top: 1px solid #d5e2e8;
    li {
      border-bottom: 1px solid #d5e2e8;
    }
    a {
      padding: 17px 24px;
      @include elip("line1");
      font-size: 18px;
      @include pc {
        padding-left: 0;
        padding-right: 0;
        font-size: 14px;
      }
    }
  }
}
