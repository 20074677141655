.subpage_top {
  padding-top: 52px;
  background-color: $sub-color4;
  @include pc {
    padding-top: 64px;
  }
  .pagetop_wrap {
    @include pc {
      @include bgSet;
      background-size: auto 100%;
    }
    &.join {
      &:after {
        background-image: url($assetsImg + "subpage_top_join.png");
      }
      @include pc {
        background-image: url($assetsImg + "mo_subpage_top_join.png");
      }
    }
    &.notice {
      &:after {
        background-image: url($assetsImg + "subpage_top_notice.png");
      }
      @include pc {
        background-image: url($assetsImg + "mo_subpage_top_notice.png");
      }
    }
    &.news {
      &:after {
        background-image: url($assetsImg + "subpage_top_news.png");
      }
      @include pc {
        background-image: url($assetsImg + "mo_subpage_top_news.png");
      }
    }
    &.faq {
      &:after {
        background-image: url($assetsImg + "subpage_top_faq.png");
      }
      @include pc {
        background-image: url($assetsImg + "mo_subpage_top_faq.png");
      }
    }
    &.refund {
      &:after {
        background-image: url($assetsImg + "subpage_top_refund.png");
      }
      @include pc {
        background-image: url($assetsImg + "mo_subpage_top_refund.png");
      }
    }
    &.inquiry {
      &:after {
        background-image: url($assetsImg + "subpage_top_inquiry.png");
      }
      @include pc {
        background-image: url($assetsImg + "mo_subpage_top_inquiry.png");
      }
    }
    &.guide {
      &:after {
        background-image: url($assetsImg + "subpage_top_guide.png");
      }
      @include pc {
        background-image: url($assetsImg + "mo_subpage_top_guide.png");
      }
    }
    &.share {
      &:after {
        background-image: url($assetsImg + "subpage_top_share.png");
      }
      @include pc {
        background-image: url($assetsImg + "mo_subpage_top_share.png");
      }
    }
    &.cancel {
      &:after {
        background-image: url($assetsImg + "subpage_top_cancel.png");
      }
      @include pc {
        background-image: url($assetsImg + "mo_subpage_top_cancel.png");
      }
    }
    &.phishing {
      &:after {
        background-image: url($assetsImg + "subpage_top_phishing.png");
      }
      @include pc {
        background-image: url($assetsImg + "mo_subpage_top_phishing.png");
      }
    }
    &.personal,
    &.used {
      &:after {
        background-image: url($assetsImg + "subpage_top_terms.png");
      }
      @include pc {
        background-image: url($assetsImg + "mo_subpage_top_terms.png");
      }
    }
  }
}
.pagetop_wrap {
  @include pos($p: relative);
  max-width: $conts-pc;
  margin: 0 auto;
  padding-top: 110px;
  padding-bottom: 110px;
  @include pc {
    max-width: $conts-mobile;
    padding: 40px 26px 200px;
  }
  &:after {
    content: "";
    display: block;
    width: 420px;
    height: 100%;
    @include pos($p: absolute, $r: 0, $t: 0, $b: 0);
    @include icoSet;
    @include pc {
      display: none;
    }
  }
  > * {
    display: block;
    @include pos($p: relative);
    color: $white;
    z-index: 1;
    @include pc {
      line-height: 1.4;
    }
  }
  span {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 18px;
    @include pc {
      margin-bottom: 0;
      font-size: 16px;
    }
  }
  strong {
    font-weight: 700;
    font-size: 40px;
    @include pc {
      font-size: 26px;
    }
  }
}
.subpage_conts {
  max-width: $conts-pc;
  padding: 70px 0 150px;
  margin: 0 auto;
  @include pc {
    max-width: $conts-mobile;
    padding-top: 24px;
  }
  @include mobile {
    max-width: $conts-min;
  }
  @include min {
    padding: 30px 16px 130px;
    width: 100%;
    max-width: none;
  }
}
.subconts_tit {
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  @include pc {
    font-size: 24px;
  }
  & + p {
    margin-top: 12px;
    text-align: center;
    font-size: 18px;
    @include pc {
      margin-top: 3px;
      font-size: 14px;
    }
  }
  & + ul {
    margin-top: 60px;
    @media all and (max-width: 1200px) {
      margin-top: 20px;
    }
  }
}
.subpage_info {
  margin-bottom: 100px;
  padding: 30px;
  background-color: #f7f7f7;
  @include pc {
    margin-bottom: 35px;
    padding: 16px;
  }
  > .title {
    display: block;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 28px;
    @include pc {
      margin-bottom: 14px;
      font-size: 22px;
    }
  }
  pre,
  li {
    font-size: 18px;
    line-height: 1.5;
    @include pc {
      font-size: 14px;
    }
  }
  li {
    @include pos($p: relative);
    padding-left: 10px;
    @include pc {
      padding-left: 8px;
    }
    &:before {
      content: "*";
      display: block;
      @include pos($p: absolute, $l: 0, $t: 0);
    }
  }
}
.notice_wrap,
.news_wrap {
  margin-top: 42px;
  border-bottom: 1px solid $blue-gray-20;
  @include tablet {
    margin-top: 30px;
  }
}
.notice_conts,
.news_conts {
  border-top: 1px solid $blue-gray-20;
  > a {
    display: flex;
    width: 100%;
    padding: 32px;
    justify-content: space-between;
    align-items: center;
    @include pc {
      padding: 20px 0;
    }
    > * {
      font-size: 20px;
      @include pc {
        font-size: 14px;
      }
    }
  }
  .notice_tit,
  .news_tit {
    font-weight: 500;
    @include elip("line1");
  }
  .notice_date,
  .news_date {
    @include pos($p: relative);
    min-width: 140px;
    text-align: right;
    font-weight: 300;
    @include pc {
      min-width: auto;
      padding-left: 14px;
    }
    &:after {
      content: "";
      display: inline-block;
      width: 13px;
      height: 13px;
      margin-left: 10px;
      border-bottom: 2px solid $blue-gray-20;
      border-right: 2px solid $blue-gray-20;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      @include pc {
        display: none;
      }
    }
  }
}
