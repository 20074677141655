.notice_detail_wrap {
  border-top: 1px solid $blue-gray-20;
  border-bottom: 1px solid $blue-gray-20;
  padding: 30px 32px 70px;
  margin-top: 42px;
  @include pc {
    padding: 20px 0 60px;
    margin-top: 30px;
  }
  .detail_tit {
    margin-bottom: 48px;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    @include pc {
      margin-bottom: 24px;
      font-size: 16px;
    }
  }
  .detail_date {
    margin-top: 9px;
    font-weight: 300;
    font-size: 20px;
    @include pc {
      margin-top: 4px;
      font-size: 14px;
    }
  }
  .detail_conts {
    line-height: 1.5;
    font-size: 18px;
    @include pc {
      font-size: 14px;
    }
  }
}
