$assetsImg: "/assets/images/";

$break-wide: 1920px;
$break-pc: 1200px;
$break-tablet: 800px;
$break-mobile: 540px;
$break-min: 360px;

$conts-wide: 1920px;
$conts-pc: 1200px;
$conts-tablet: 800px;
$conts-mobile: 540px;
$conts-min: 360px;

@mixin wide {
  @media all and (max-width: #{$break-wide}) {
    @content;
  }
}
@mixin pc {
  @media all and (max-width: #{$break-pc}) {
    @content;
  }
}
@mixin tablet {
  @media all and (max-width: #{$break-tablet}) {
    @content;
  }
}
@mixin mobile {
  @media all and (max-width: #{$break-mobile}) {
    @content;
  }
}
@mixin min {
  @media all and (max-width: #{$break-min}) {
    @content;
  }
}
@mixin clearfix {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}
@mixin pos($p: null, $l: null, $r: null, $t: null, $b: null) {
  position: $p;
  left: $l;
  right: $r;
  top: $t;
  bottom: $b;
}
@mixin icoSet {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100% 100%;
}
@mixin bgSet {
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
@mixin elip($type) {
  @if $type == "line1" {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    overflow: hidden;
    max-width: 100%;
  } @else if $type == "line2" {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* 라인수 */
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  } @else if $type == "line3" {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* 라인수 */
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }
}

@keyframes updown {
  0% {
    transform: translateY(-16px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-16px);
  }
}
@-webkit-keyframes updown {
  0% {
    transform: translateY(-16px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-16px);
  }
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@mixin animateLoop($name, $s) {
  animation-name: $name;
  animation-duration: $s + s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
}
