$white: #fff;
$black: #000;

$main-color1: #3dc289;
$point-color1: #f55441;
$red-color1: #ff2424;
$green-color1: #00a256;

$sub-color1: #68f3b8;
$sub-color2: #2e936f;
$sub-color3: #3c4659;
$sub-color4: #272e40;

$gray-10: #f7f7f7;
$gray-20: #e4e4e4;
$gray-30: #bfbfbf;
$gray-40: #aaa;
$gray-50: #808080;
$gray-70: #404040;
$gray-90: #202020;

$blue-gray-10: #e7ecf5;
$blue-gray-20: #ced6e3;
$blue-gray-30: #a1aab9;
$blue-gray-50: #7a828f;
$blue-gray-70: #3c4659;
$blue-gray-80: #1c2439;
