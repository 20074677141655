.faq_item {
  overflow: hidden;
  &.show {
    .faq_item_q {
      button {
        &:after {
          transform: rotate(-225deg);
          -webkit-transform: rotate(-225deg);
        }
      }
    }
    .faq_item_a {
      max-height: 999px;
      transition: max-height 0.9s ease-in-out;
    }
  }
}
.faq_item_q {
  border-bottom: 1px solid $blue-gray-20;
  > button {
    display: block;
    width: 100%;
    @include pos($p: relative);
    padding: 32px;
    text-align: left;
    @include pc {
      padding: 20px 0;
    }
    &:after {
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      @include pos($p: absolute, $r: 32px, $t: 50%);
      margin-top: -6px;
      border-left: 2px solid $blue-gray-20;
      border-bottom: 2px solid $blue-gray-20;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      @include pc {
        display: none;
      }
    }
  }
  .faq_tit {
    font-weight: 700;
    font-size: 20px;
    color: $sub-color4;
    @include pc {
      font-size: 14px;
    }
    &:before {
      content: "Q. ";
    }
  }
}
.faq_item_a {
  max-height: 0;
  background-color: $gray-10;
  transition: max-height 0.3s ease-in-out;
  pre {
    padding: 20px 32px;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    @include pc {
      padding: 16px;
      font-size: 14px;
    }
  }
}
